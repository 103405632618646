import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LegalLayout from "../../components/layouts/legal_layout";
export const _frontmatter = {
  "title": "DemandByte Terms of Service",
  "description": "The general terms and conditions of your use of the DemandByte website."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LegalLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`DemandByte Terms of Service`}</h1>
    <p>{`These terms and conditions (“Terms”, “Agreement”) are an agreement between DemandByte International, Inc. (“DemandByte”, “us”, “we” or “our”) and you (“User”, “you” or “your”). This Agreement sets forth the general terms and conditions of your use of the `}<a parentName="p" {...{
        "href": "https://www.demandbyte.com/"
      }}>{`http://www.demandbyte.com`}</a>{` website and any of its products or services (collectively, “Website” or “Services”).`}</p>
    <p><strong parentName="p">{`Accuracy of information`}</strong></p>
    <p>{`Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated.`}</p>
    <p><strong parentName="p">{`Backups`}</strong></p>
    <p>{`We are not responsible for Content residing on the Website. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own purposes. We make no guarantee that the data you need will be available.`}</p>
    <p><strong parentName="p">{`Links to other websites`}</strong></p>
    <p>{`Although this Website may be linked to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services and content of any other third parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site websites is at your own risk.`}</p>
    <p><strong parentName="p">{`Intellectual property rights`}</strong></p>
    <p>{`This Agreement does not transfer from DemandByte to you any DemandByte or third-party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with DemandByte. All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of DemandByte or DemandByte licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any DemandByte or third-party trademarks.`}</p>
    <p><strong parentName="p">{`Disclaimer of warranty`}</strong></p>
    <p>{`You agree that your use of our Website or Services is solely at your own risk. You agree that such Service is provided on an “as is” and “as available” basis. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.`}</p>
    <p><strong parentName="p">{`Limitation of liability`}</strong></p>
    <p>{`To the fullest extent permitted by applicable law, in no event will DemandByte, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if DemandByte has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of DemandByte and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to DemandByte for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.`}</p>
    <p><strong parentName="p">{`Indemnification`}</strong></p>
    <p>{`You agree to indemnify and hold DemandByte and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services or any willful misconduct on your part.`}</p>
    <p><strong parentName="p">{`Dispute resolution`}</strong></p>
    <p>{`The formation, interpretation and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of New York, United States without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of United States. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the state and federal courts located in New York, United States, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.`}</p>
    <p><strong parentName="p">{`Changes and amendments`}</strong></p>
    <p>{`We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.`}</p>
    <p><strong parentName="p">{`Acceptance of these terms`}</strong></p>
    <p>{`You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.`}</p>
    <p><strong parentName="p">{`Contacting us`}</strong></p>
    <p>{`If you have any questions about this Agreement, please `}<a parentName="p" {...{
        "href": "mailto:info@demandbyte.com"
      }}>{`contact DemandByte`}</a>{`.`}</p>
    <p>{`This document was last updated on August 1, 2020.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      